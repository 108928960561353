export const addToSidebarHistory = (sidebarFormValues, step) => {
    const stepsHistory = [...((sidebarFormValues === null || sidebarFormValues === void 0 ? void 0 : sidebarFormValues.stepsHistory) || [])];
    stepsHistory.push(step);
    return stepsHistory;
};
export const removeFromSidebarHistory = (sidebarFormValues) => {
    const stepsHistory = [...((sidebarFormValues === null || sidebarFormValues === void 0 ? void 0 : sidebarFormValues.stepsHistory) || [])];
    const prevStep = stepsHistory.pop();
    return { updatedHistory: stepsHistory, prevStep };
};
export const mapFieldsForExchangeCreationReq = (values) => ({
    api_key: values.apiKey,
    connection_name: values.exchangeName,
    secret_key: values.secretKey,
});
export const mapFieldsForPortfolioCreationReq = (values) => ({
    walletId: values.walletId,
    portfolioName: values.portfolioName,
    subscriptionPrice: values.monthlyFee,
});
