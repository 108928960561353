var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useDispatch } from 'react-redux';
import { updateSidebarValues, } from '@entities/exchange';
import { useWizard } from '@shared/ui/misc/Wizard';
import { useAppSelector } from '@app/store/Hooks';
import { useCreateExchangeAccountMutation, useGetExchangeAccountsQuery } from '@shared/api/exchange';
import { useCreatePortfolioMutation } from '@shared/api/portfolio';
import { getWalletsFromExchanges } from '@entities/wallet';
import { addToSidebarHistory, mapFieldsForExchangeCreationReq, mapFieldsForPortfolioCreationReq, removeFromSidebarHistory, } from './utils';
var CreatePortfolioStepsEnum;
(function (CreatePortfolioStepsEnum) {
    CreatePortfolioStepsEnum[CreatePortfolioStepsEnum["SelectExchange"] = 0] = "SelectExchange";
    CreatePortfolioStepsEnum[CreatePortfolioStepsEnum["EnterKey"] = 1] = "EnterKey";
    // ConnectExchange = 1,
    CreatePortfolioStepsEnum[CreatePortfolioStepsEnum["SelectWallet"] = 2] = "SelectWallet";
    CreatePortfolioStepsEnum[CreatePortfolioStepsEnum["PortfolioSettings"] = 3] = "PortfolioSettings";
    CreatePortfolioStepsEnum[CreatePortfolioStepsEnum["Success"] = 4] = "Success";
    CreatePortfolioStepsEnum[CreatePortfolioStepsEnum["ErrorLimit"] = 5] = "ErrorLimit";
    CreatePortfolioStepsEnum[CreatePortfolioStepsEnum["CreationError"] = 6] = "CreationError";
})(CreatePortfolioStepsEnum || (CreatePortfolioStepsEnum = {}));
const usePortfolioCreate = ({ handleClose }) => {
    var _a;
    const { current, goTo } = useWizard();
    const { sidebarFormValues, } = useAppSelector(state => state.exchange);
    const { currentUserProfile } = useAppSelector(state => state.profile);
    const { user } = useAppSelector(state => state.user);
    const dispatch = useDispatch();
    const [createEchangeReq, { isLoading: isCreatingExchangeAcc },] = useCreateExchangeAccountMutation();
    const [createPortfolioReq, { isLoading: isCreatingPortfolio }] = useCreatePortfolioMutation();
    const { data: { exchangesList, wallets }, } = useGetExchangeAccountsQuery(undefined, {
        selectFromResult: (_a) => {
            var { data } = _a, rest = __rest(_a, ["data"]);
            return (Object.assign(Object.assign({}, rest), { data: {
                    exchangesList: (data || []),
                    wallets: getWalletsFromExchanges(data || []),
                } }));
        },
    });
    const handleExchangeCreate = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const mappedFields = mapFieldsForExchangeCreationReq(values);
        const res = yield createEchangeReq(mappedFields);
        if (res === null || res === void 0 ? void 0 : res.data) {
            goTo(CreatePortfolioStepsEnum.SelectWallet);
            return true;
        }
        return { error: res === null || res === void 0 ? void 0 : res.error };
    });
    const handlePortfolioCreate = (values) => __awaiter(void 0, void 0, void 0, function* () {
        if (currentUserProfile) {
            const mappedFields = mapFieldsForPortfolioCreationReq(values);
            const res = yield createPortfolioReq(Object.assign({ profileId: currentUserProfile.id }, mappedFields));
            if (res === null || res === void 0 ? void 0 : res.data) {
                goTo(CreatePortfolioStepsEnum.Success);
                return true;
            }
            return { error: res === null || res === void 0 ? void 0 : res.error };
        }
        return false;
    });
    const goToNextStep = (values) => {
        const updatedHistory = addToSidebarHistory(sidebarFormValues, current);
        switch (current) {
            case 0:
                if (values) {
                    // TODO: move this logic to redux slice
                    dispatch(updateSidebarValues(Object.assign(Object.assign({}, values), { stepsHistory: updatedHistory })));
                    // goTo(CreatePortfolioStepsEnum.ConnectExchange)
                    goTo(CreatePortfolioStepsEnum.EnterKey);
                }
                break;
            case 1:
                if (values) {
                    dispatch(updateSidebarValues(Object.assign(Object.assign({}, values), { stepsHistory: updatedHistory })));
                    goTo(CreatePortfolioStepsEnum.SelectWallet);
                }
                break;
            case 2:
                if (values) {
                    dispatch(updateSidebarValues(Object.assign(Object.assign({}, values), { stepsHistory: updatedHistory })));
                    goTo(CreatePortfolioStepsEnum.PortfolioSettings);
                }
                break;
            case 3:
                if (values) {
                    dispatch(updateSidebarValues(Object.assign(Object.assign({}, values), { stepsHistory: updatedHistory })));
                    handlePortfolioCreate(values);
                }
                break;
            case 4:
            case 5:
            case 6:
                handleClose();
                break;
            default:
                break;
        }
    };
    const goToPrevStep = () => {
        const { updatedHistory, prevStep } = removeFromSidebarHistory(sidebarFormValues);
        switch (current) {
            case 0:
            case 1:
            case 2:
            case 3:
                dispatch(updateSidebarValues({ stepsHistory: updatedHistory }));
                if (typeof prevStep === 'number') {
                    goTo(prevStep);
                }
                break;
            default:
                break;
        }
    };
    const handleExchangeConnect = () => {
        const updatedHistory = addToSidebarHistory(sidebarFormValues, current);
        dispatch(updateSidebarValues({ stepsHistory: updatedHistory }));
        // goTo(CreatePortfolioStepsEnum.ConnectExchange)
        goTo(CreatePortfolioStepsEnum.SelectExchange);
    };
    return {
        goToNextStep,
        goToPrevStep,
        sidebarFormValues,
        handleExchangeConnect,
        handleExchangeCreate,
        handleClose,
        currentUserProfile,
        userId: (user === null || user === void 0 ? void 0 : user.id) || '',
        displayBackBtn: ((_a = sidebarFormValues === null || sidebarFormValues === void 0 ? void 0 : sidebarFormValues.stepsHistory) === null || _a === void 0 ? void 0 : _a.length) > 0,
        isVerified: true,
        isCreatingExchangeAcc,
        isCreatingPortfolio,
        wallets: wallets || [],
        exchanges: exchangesList || [],
    };
};
export default usePortfolioCreate;
